document.body.addEventListener('click', ev => {
  let wrapper = ev.target.closest('.video-wrapper');
  if (wrapper) {
    let video = wrapper.querySelector('video');
    let playIcon = wrapper.querySelector('.play');
    if (video.paused) {
      video.controls = true;
      setTimeout(() => {
        video.play();
        playIcon.style.display = 'none';
        wrapper.classList.add('is-playing');
        wrapper.classList.remove('is-paused');
      }, 0);
    } else {
      video.controls = false;
      setTimeout(() => {
        video.pause();
        playIcon.style.display = '';
        wrapper.classList.add('is-paused');
        wrapper.classList.remove('is-playing');
      }, 0);
    }
  }
});