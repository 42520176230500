import Litepicker from 'litepicker';
import moment from 'moment';
import Hammer from 'hammerjs';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import {Member} from '../utils/travelanium';
import {__, _n, sprintf} from '@wordpress/i18n';

let datepickerMethods;

function bookingFormAlert( html ) {
  return Fancybox.show([
    {
      src: html,
      type: 'html',
    }
  ], {
    mainClass: 'booking-form-alert',
    on: {
      closing() {
        setTimeout(() => {
          datepickerMethods.show();
        }, 100);
      },
    }
  });
}

let body = document.body;
let showClass = 'reservation-panel-show';

const PanelMethods = {
  show() {
    body.classList.add(showClass);
  },
  hide() {
    body.classList.remove(showClass);
  },
  toggle() {
    if (this.isShow()) {
      this.hide();
    } else {
      this.show();
    }
  },
  isShow() {
    return body.classList.contains(showClass);
  }
}

window.therock.fn['reservationPanel'] = PanelMethods;

let panelEl = document.querySelector('.reservation-panel');

if (panelEl) {
  let panelForm = panelEl.querySelector('.form');
  
  const {
    defaultReservationStart,
    defaultReservationEnd,
    defaultPromotionCode
  } = therock.reservation;
  
  let reservationDate = panelEl.querySelector('.reservation-date');
  let dateCheckin = panelForm.querySelector('[name=checkin]');
  let dateCheckout = panelForm.querySelector('[name=checkout]');
  
  let dateUI = reservationDate.querySelector('.date-ui');
  let dateNight = reservationDate.querySelector('.date-night');
  let datePicker = reservationDate.querySelector('.date-picker');
  let dateUICheckin = reservationDate.querySelector('.date-ui-checkin');
  let dateUICheckout = reservationDate.querySelector('.date-ui-checkout');
  
  dateUI.tabIndex = 0;
  
  let datePickerShowClass = 'date-picker-show';

  datepickerMethods = {
    show() {
      reservationDate.classList.add(datePickerShowClass);
      document.addEventListener('click', handleHideDatepickerOnClick);
    },
    hide() {
      reservationDate.classList.remove(datePickerShowClass);
      document.removeEventListener('click', handleHideDatepickerOnClick);
    },
    toggle() {
      reservationDate.classList.toggle(datePickerShowClass, !this.isShow());
    },
    isShow() {
      return reservationDate.classList.contains(datePickerShowClass);
    },
    setCheckin( date ) {
      this._setDate( date, dateUICheckin );
      this._setMonth( date, dateUICheckin );
      this._setYear( date, dateUICheckin );
      this._setDay( date, dateUICheckin );

      if ( date instanceof Date ) {
        dateCheckin.value = moment(date).format('YYYY-MM-DD');
      }
    },
    setCheckout( date ) {
      this._setDate( date, dateUICheckout );
      this._setMonth( date, dateUICheckout );
      this._setYear( date, dateUICheckout );
      this._setDay( date, dateUICheckout );

      if ( date instanceof Date ) {
        dateCheckout.value = moment(date).format('YYYY-MM-DD');
      }

      if ( datepickerInst.getStartDate() && datepickerInst.getEndDate() ) {
        this._setNight(
          datepickerInst.getStartDate().dateInstance,
          datepickerInst.getEndDate().dateInstance,
        );
      } else {
        this._setNight( null );
      }
    },
    _setDate(date, parent) {
      let ui = parent.querySelector('.d');
      if (date instanceof Date) {
        ui.innerText = moment(date).format('DD');
      } else {
        ui.innerText = '--';
      }
    },
    _setMonth(date, parent) {
      let ui = parent.querySelector('.m');
      if (date instanceof Date) {
        ui.innerText = moment(date).format('MMM');
      } else {
        ui.innerText = '---';
      }
    },
    _setYear(date, parent) {
      let ui = parent.querySelector('.y');
      if (date instanceof Date) {
        ui.innerText = moment(date).format('YYYY');
      } else {
        ui.innerText = '----';
      }
    },
    _setDay(date, parent) {
      let ui = parent.querySelector('.day');
      if (date instanceof Date) {
        ui.innerText = moment(date).format('dddd');
      } else {
        ui.innerText = '---';
      }
    },
    _setNight(a, b) {
      let ui = dateNight.querySelector('.n');
      if ( a instanceof Date && b instanceof Date ) {
        let dateA = moment(a);
        let dateB = moment(b);
        let diff = Math.abs(dateB.diff(dateA, 'd'));
        /* Translators: %s is number of night */
        ui.innerText = sprintf( _n('%s night', '%s nights', diff, 'therock'), diff );;
      } else {
        ui.innerText = '···';
      }
    }
  }
  
  function handleHideDatepickerOnClick( ev ) {
    let target = ev.target;
    let notDateUI = !target.closest('.date-ui');
    let notAirDatepicker = !target.closest('.air-datepicker');
    let notLitepicker = !target.closest('.litepicker');
    let notYearText = !(target.matches('i') && target.innerText.length === 4);

    let notMonthItem = !target.closest('.month-item');

    if ( notDateUI && notLitepicker && notMonthItem ) {
      datepickerMethods.hide();
    }
  }
  
  dateUI.addEventListener('focus', () => datepickerMethods.show());
  
  let today = new Date().setHours(0,0,0,0);

  let datepickerInst = new Litepicker({
    element: datePicker,
    minDate: today,
    inlineMode: true,
    singleMode: false,
    minDays: 2,
    firstDay: 0,
    lang: document.documentElement.lang.substring(0, 2) || 'en',
    buttonText: {
      previousMonth: '<i class="fas fa-arrow-circle-left"></i>',
      nextMonth: '<i class="fas fa-arrow-circle-right"></i>',
    },
    tooltipNumber: (n) => {
      return n-1;
    },
    tooltipText: {
      one: __('night', 'therock'),
      other: __('nights', 'therock'),
    },
    setup: picker => {
      picker.on('selected', (a, b) => {
        datepickerMethods.setCheckin(a.dateInstance);
        datepickerMethods.setCheckout(b.dateInstance);
        datepickerMethods.hide();
      });
    }
  });

  let cid = moment().add(defaultReservationStart, 'day').toDate();
  let cod = moment().add(defaultReservationStart + defaultReservationEnd, 'day').toDate();

  datepickerInst.setDateRange(cid, cod);
  
  datePicker.Datepicker = datepickerMethods;
  
  panelForm.addEventListener('submit', ev => {
    ev.preventDefault();
  
    let action = panelForm.action;
    let data = new FormData(panelForm);
    let params = '';
    let count = 0;
  
    let errors = [];
  
    if ( !datepickerInst.getStartDate() || !datepickerInst.getEndDate() ) {
      errors.push( __('Please select reservation date', 'therock') );
    }
  
    if (errors.length) {
      bookingFormAlert( errors.toString() );
      return;
    }

    if ( !data.get('accesscode') && Member.isMember() ) {
      data.append('accesscode', therock.member.code);
    }
  
    if ( !data.get('accesscode') && defaultPromotionCode ) {
      data.append('accesscode', defaultPromotionCode);
    }

    for( let pair of data.entries() ) {
      let key = pair[0];
      let val = pair[1];
      let conj = count > 0 ? '&' : '';
      if (!val) continue;
      params += `${conj}${key}=${val}`;
      count++;
    }
  
    return window.open( action + '?' + params, '_blank' );
  });
  
  /**
   * Implement drag/touch function
   */
  let r = new Hammer(panelEl.querySelector('.reservation-panel__body'));
  let rStart, rOffset = 100;
  
  r.on('panstart', ev => {
    let {deltaX} = ev;
    rStart = deltaX;
  });
  
  r.on('panmove', ev => {
    let {deltaX, additionalEvent} = ev;
    let allowed = ['panleft', 'panright'];
  
    if (allowed.includes(additionalEvent)) {
      let distance = deltaX - rStart;
  
      if (distance >= 0) {
        r.element.style.transitionDuration = '0ms';
        r.element.style.transform = `translateX(${distance}px)`;
      }
    }
  });
  
  r.on('panend', ev => {
    let {deltaX} = ev;
    let distance = deltaX - rStart;
  
    r.element.style.transitionDuration = ''
    r.element.style.transform = '';
  
    if (distance >= rOffset) {
      therock.fn.reservationPanel.hide();
    }
  });
  
  let bg = panelEl.querySelector('.reservation-panel__bg');
  bg.addEventListener('wheel', ev => {
    if (ev.deltaY !== 0) {
      therock.fn.reservationPanel.hide();
    }
  }, {
    passive: true,
  });
  
  //
  // Open booking panel via classname
  // 
  document.body.addEventListener('click', ev => {
    let target = ev.target;
    if (target.closest('.js-reservation-panel')) {
      ev.preventDefault();
      therock.fn.reservationPanel.show();
    }
  });
}
