// 
// Component: Spinner
// 
// How to use in HTML
// ==========
// 
// <div
//   data-counter="field_name"
//   data-counter-id="field_id"
//   data-counter-min="min_num"
//   data-counter-max="max_num"
//   data-counter-value="val_num"
//   data-counter-icon-inc="+"
//   data-counter-icon-dec="-"
// ></div>
// 

/**
 * Create spinner by div with data
 *
 * @param {HTMLElement} element
 */
function createSpinner( element ) {
  let counter = element;

  let id = counter.dataset.counterId;
  let name = counter.dataset.counter;
  let max = parseInt(counter.dataset.counterMax);
  let min = parseInt(counter.dataset.counterMin);
  let val = parseInt(counter.dataset.counterValue);
  let iconInc = counter.dataset.counterIconInc || '+';
  let iconDec = counter.dataset.counterIconDec || '-';

  let hasMax = !Number.isNaN(max);
  let hasMin = !Number.isNaN(min);

  let classList = {
    element: 'counter-ui',
    disable: 'counter-ui--disabled',
    inc: 'inc',
    dec: 'dec',
    input: 'val',
  }

  let input = document.createElement('input');
  input.type = 'number';
  input.value = val;
  if (id) input.id = id;
  if (name) input.name = name;
  if (hasMin) input.min = min;
  if (hasMax) input.max = max;

  let dec = document.createElement('button');
  dec.type = 'button';
  dec.innerHTML = iconDec;
  
  let inc = document.createElement('button');
  inc.type = 'button';
  inc.innerHTML = iconInc;
  
  let decWrapper = document.createElement('span');
  decWrapper.classList.add( classList.dec );
  decWrapper.appendChild(dec);
  
  let incWrapper = document.createElement('span');
  incWrapper.classList.add( classList.inc );
  incWrapper.appendChild(inc);
  
  let inputWrapper = document.createElement('span');
  inputWrapper.classList.add( classList.input );
  inputWrapper.appendChild(input);

  counter.ariaDisabled = false;
  counter.classList.add( classList.element );
  counter.insertAdjacentElement('beforeend', inputWrapper);
  counter.insertAdjacentElement('afterbegin', decWrapper);
  counter.insertAdjacentElement('beforeend', incWrapper);

  let buttonCheck = () => {
    let value = input.value;
    inc.disabled = hasMax && max <= value;
    dec.disabled = hasMin && min >= value;
  }

  let inputCheck = () => {
    let value = parseInt(input.value);

    if (hasMax && hasMin) {
      input.value = Math.min( max, Math.max( min, value ) );
    } else if (hasMax) {
      input.value = Math.min( max, value );
    } else if (hasMin) {
      input.value = Math.max( min, value );
    }
  }

  let set = value => {
    let val = Math.min( max, Math.max( min, value ) );
    input.value = val;
    buttonCheck();
    inputCheck();
  }

  let get = () => {
    return parseInt(input.value);
  }

  let increase = value => {
    if (hasMax) {
      if (max > value) input.value++;
    } else {
      input.value++;
    }
  }

  let decrease = value => {
    if (hasMin) {
      if (min < value) input.value--;
    } else {
      input.value--;
    }
  }

  let setDisable = () => {
    counter.ariaDisabled = true;
    counter.classList.add( classList.disable );
    input.disabled = true;
    inc.disabled = true;
    dec.disabled = true;
  }
  
  let setEnable = () => {
    counter.ariaDisabled = false;
    counter.classList.remove( classList.disable );
    input.disabled = false;
    inc.disabled = false,
    dec.disabled = false,
    buttonCheck();
    inputCheck();
  }

  buttonCheck();
  inputCheck();

  input.addEventListener('blur', ev => {
    inputCheck();
    buttonCheck();
  });

  input.addEventListener('change', ev => {
    inputCheck();
    buttonCheck();
  });

  let
  hold,
  holding;

  /**
   * Handle mouse/touch holding event
   * 
   * @param {MouseEvent|TouchEvent} ev 
   */
  function handleHoldEvent(ev) {
    let target = ev.target;
    let value = Number.isNaN( parseInt(input.value) ) ? '1' : parseInt(input.value);

    if (target.closest('.inc')) {
      ev.preventDefault();
      increase(value);
      hold = setTimeout(() => {
        holding = setInterval(() => increase(input.value), 50);
      }, 300);
    }

    if (target.closest('.dec')) {
      ev.preventDefault();
      decrease(value);
      hold = setTimeout(() => {
        holding = setInterval(() => decrease(input.value), 50);
      }, 300);
    }
  }

  /**
   * Handle mouse/touch released event
   * 
   * @param {MouseEvent|TouchEvent} ev 
   */
  function handleHoldEndEvent(ev) {
    ev.preventDefault();

    clearTimeout(hold);
    clearInterval(holding);
    inputCheck();
    buttonCheck();
  }

  // for Mouse
  counter.addEventListener('mousedown', handleHoldEvent, {passive:true});
  counter.addEventListener('mouseup', handleHoldEndEvent, {passive:true});

  // for Touch Screen
  counter.addEventListener('touchstart', handleHoldEvent, {passive:true});
  counter.addEventListener('touchend', handleHoldEndEvent, {passive:true});

  // for Keyboard
  counter.addEventListener('keydown', ev => {
    if ('Space' !== ev.code) return;

    let target = ev.target;
    let value = Number.isNaN( parseInt(input.value) ) ? '1' : parseInt(input.value);

    if (target.closest('.inc')) increase(value);
    if (target.closest('.dec')) decrease(value);
  });

  counter.addEventListener('keyup', ev => {
    if ('Space' !== ev.code) return;
    inputCheck();
    buttonCheck();
  });

  let Methods = {
    inc() {
      increase( input.value );
      buttonCheck();
      inputCheck();
    },
    dec() {
      decrease( input.value );
      buttonCheck();
      inputCheck();
    },
    enable() {
      setEnable()
    },
    disable() {
      setDisable()
    },
    isDisabled() {
      return counter.classList.contains('is-disabled');
    },
    get() {
      return get();
    },
    set(val) {
      set(val);
    },
  }

  counter.Counter = Methods;
}

let counters = document.querySelectorAll('[data-counter]');
counters.forEach( counter => createSpinner(counter) );

export default createSpinner;