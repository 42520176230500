import $ from 'jquery';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import {WebsiteData, Member, Booking} from '../utils/travelanium';
import {__, sprintf} from '@wordpress/i18n';

let inst, $wrapper;

const Methods = {
  show() {
    inst = Member.isMember()
      ? showModal( memberBoard( therock.member.code ) )
      : showModal( memberForm() );
  },

  hide() {
    inst.close();
  },

  alert( msg, type ) {
    let $el = $wrapper.querySelector('.member-notice');
    $el.innerHTML = `<div class="text text--${type} alert-dismissible" role="alert">${msg}</div>`;
  },

  alertClear() {
    let $el = $wrapper.querySelector('.member-notice');
    $el.innerHTML = '';
  }
}

function showModal( content ) {
  return Fancybox.show( [
    {
      type: 'html',
      src: content,
    }
  ], {
    mainClass: 'member-modal-container',
    dragToClose: false,
    trapFocus: false,
    autoFocus: false,
    on: {
      reveal: (fancybox, slide) => {
        let $content = slide.$content;

        $wrapper = $content;

        $($content).find('.inline-text').each((i, el) => {
          var l = el.value.length;
          el.style.width = l + 'ch';
        });

        $($content).find('.booking-link').each((i, el) => {
          var inst = new Booking({
            accesscode: therock.member.code,
          });
          el.setAttribute('href', inst.getURL());
          el.setAttribute('target', '_blank');
        });

        $content.style.minHeight = slide.$content.clientHeight + 'px';

        Array.from( $content.querySelectorAll('form') ).forEach($form => {
          $form.addEventListener('submit', event => {
            event.preventDefault();

            if (!$form.checkValidity()) {
              event.stopPropagation();
            } else {
              handleFormSubmit( $form );
            }

            $form.classList.add('was-validated');
          }, false);
        });
      }
    },
  } );
}

function handleFormSubmit( $form ) {
  let inputs = $form.getElementsByTagName('input');
  let buttons = $form.getElementsByTagName('button');

  new WebsiteData({
    formKey: therock.api.member_apikey,
    name: $form.querySelector('[name="name"]').value,
    email: $form.querySelector('[name="email"]').value,
    message: 'Member subscribe from The Rock Hua Hin website.'
  }, {
    beforeSend( xhr ) {
      $form.classList.add('is-sending');
      Methods.alertClear();
      Array.from(inputs).forEach(el => el.disabled = true);
      Array.from(buttons).forEach(el => el.disabled = true);
    },
    done() {
      $form.classList.add('is-success');
      Member.setMember();
      setTimeout(() => {
        Methods.hide();
        setTimeout(Methods.show(), 300);
      }, 2500);
    },
    fail() {
      Methods.alert( __('Sorry, There is something error.', 'therock'), 'error' );
    },
    always() {
      $form.classList.remove('is-sending');
      Array.from(inputs).forEach(el => el.disabled = false);
      Array.from(buttons).forEach(el => el.disabled = false);
    }
  });
}

function memberForm() {
  return `
  <div class="member-modal">
    <div class="member-modal__inner">
      <div class="member-form-wrapper">
        <form class="member-form need-validations" action="/" method="POST" novalidate>
          <h2 class="member-modal__heading mb-3">${ __('Sign up to get<br/><span class="text-secondary"><b>PROMO COUPON</b></span>', 'therock') }</h2>
          <div class="member-notice"></div>
          <div class="row g-3 g-sm-4">
            <div class="col-12 col-sm-6">
              <div class="mb-1"><label for="member_form_name">${__('Name', 'therock')}</label></div>
              <div class="position-relative">
                <input name="name" type="text" class="form-control" id="member_form_name" placeholder="${ __('Your name', 'therock') }" required />
                <div class="invalid-feedback">${__('Please insert your name', 'therock')}</div>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="mb-1"><label for="member_form_email">${__('Email Address', 'therock')}</label></div>
              <div class="position-relative">
                <input name="email" type="email" class="form-control" id="member_form_email" placeholder="${ __('Your email address', 'therock') }" required />
                <div class="invalid-feedback">${__('Please insert your email', 'therock')}</div>
              </div>
            </div>
            <div class="col-12">
              <button type="submit" class="button button--primary d-block w-100 rounded text-uppercase">${ __('Subscribe', 'therock') }</button>
            </div>
          </div>
        </form>
        <div class="member-result">
          <div class="icon"><i class="fal fa-check-circle"></i></div>
          <div class="text">${__('Thanks for your subscription', 'therock')}</div>
        </div>
      </div>
    </div>
  </div>
  `;
}

function memberBoard( code ) {
  return `
  <div class="member-modal">
    <div class="member-modal__inner">
      <div class="member-board text-center">
        <h2 class="member-medal__heading mb-3">${__('You are now Member', 'therock')}</h2>
        <p class="mb-0">
          ${__('You may keep this code:', 'therock')}
          <input class="inline-text" value="${code}" onfocus="this.select()">
          ${__('or', 'therock')}
          <a class="booking-link" href="javascript:;">
            ${
              /* Translators: %s is external link icon */
              sprintf(__('book now %s', 'therock'), '<i class="fal fa-external-link"></i>')
            }
          </a>
        </p>
      </div>
    </div>
  </div>
  `;
}

// Methods.show();

if (!window.therock) {
  window.therock = {};
} else if (!window.therock.fn) {
  window.therock['fn'] = {};
} else if (!window.therock.fn.memberForm) {
  window.therock.fn['memberModal'] = Methods;
}