import $ from 'jquery';
import AOS from 'aos';
import Isotope from 'isotope-layout';
import WebFont from 'webfontloader';
import SVGInject from '@iconfu/svg-inject';
import AirDatepicker from 'air-datepicker';
import Fancybox from '@fancyapps/ui/src/Fancybox';
import anime from 'animejs';
import {WebsiteData, Member, Booking} from './utils/travelanium';
import ImageSwap from './utils/image-swap';
import 'nodelist-foreach-polyfill';

if ('undefined' === typeof window.therock.fn) {
  window.therock.fn = {};
}

WebFont.load({
  google: {
    families: [
      "Noto Sans Thai",
      "Prompt",
      "Sriracha"
    ]
  },
  custom: {
    families: [
      "Caveat",
      "Comfortaa",
      "Graphik"
    ],
    urls: [
      `${therock.wp.theme_uri}/assets/fonts/caveat/Caveat.css`,
      `${therock.wp.theme_uri}/assets/fonts/comfortaa/Comfortaa.css`,
      `${therock.wp.theme_uri}/assets/fonts/graphik/Graphik.css`,
    ],
  },
});

let masonryEl = document.querySelectorAll('.is-masonry');
masonryEl.forEach(el => {
  new Isotope(el, {
    itemSelector: '.is-masonry-item',
    layoutMode: 'masonry',
  });
});

//
// Animate on scroll
// 
AOS.init({
  once: true,
});

//
// Default AirDatepicker
// 
const AllowedLang = ['en', 'zh', 'th'];
const DocLang = document.documentElement.lang.substring(0, 2);
const Lang = AllowedLang.indexOf(DocLang) > -1 ? DocLang : 'en';
const AirLang = {
  en: require('air-datepicker/locale/en').default,
  zh: require('air-datepicker/locale/zh').default,
  th: require('air-datepicker/locale/th').default,
}

AirDatepicker.defaults.locale = AirLang[Lang];

//
// Default for Fancybox
// 
Fancybox.Plugins.Toolbar.defaults.display = ['counter', 'zoom', 'thumbs', 'close'];

//
// SVG
// 
let svgImageEl = document.querySelectorAll('img.inline-svg[src$=svg]');
SVGInject(svgImageEl);

//
// Scroll down button
// 
$(document.body).on('click', '.icon-scroll-down', ev => {
  ev.preventDefault();
  let target = ev.currentTarget;
  let $href  = $(target.getAttribute('href'));
  let offset = parseInt(target.dataset.offset);
  if ($href.length) {
    scrollTo({
      behavior: 'smooth',
      top: $href.offset().top - offset,
    });
  }
});

//
// Footer
// 
new ImageSwap( '.img-swap', {
  className: 'mb-0',
} );

//
// Quick Links Animation
// 
function quickLinksInit() {
  return anime({
    targets: ['.quick-links li'],
    delay: anime.stagger(50),
    opacity: [0, 1],
    translateX: [-20, 0],
  });
}

if ('complete' !== document.readyState) {
  document.addEventListener('DOMContentLoaded', quickLinksInit);
} else {
  quickLinksInit();
}

//
// Send mail event
// 
document.addEventListener('wpcf7mailsent', ev => {
  let data = ev.detail.inputs;
  let o = {};
  
  data.forEach(e => o[e.name] = e.value);
  
  if ('contact_form' === o.form_type) {
    var apiKey = therock.api.contact_apikey;
    if (apiKey) {
      new WebsiteData({
        formKey: apiKey,
        name: `${o.prefix} ${o.first_name} ${o.last_name}`,
        phone: o.tel,
        email: o.email,
        countryName: o.country,
        message: `Subject: ${o.subject}\n\n${o.message}`,
      });
    }
  }

  if ('subscribe_form' === o.form_type) {
    var apiKey = therock.api.newsletter_apikey;
    if (apiKey) {
      new WebsiteData({
        formKey: apiKey,
        email: o.client_email,
        message: `Send from footer.`,
      });
    }
  }
}, false);

//
// Member injection
// 
if ( Member.isMember() ) {
  Member.transformLink();
}

require('./components/header');
require('./components/bottom-nav');
require('./components/blog-filter');
require('./components/video-wrapper');
require('./components/reservation-panel');
require('./components/fullscreen-menu-panel');
require('./components/spinner');
require('./components/member-modal');