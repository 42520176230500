import {debounce, throttle} from 'lodash';

/**
 * Scroll Direction
 * @param {HTMLElement} el
 */
export function scrollDirection(el, opts) {
  var settings = {
    offset: 60,
    upOffset: null,
    downOffset: null,
    ...opts,
  }
  var offset = settings.offset;
  var upOffset = settings.upOffset || offset;
  var downOffset = settings.downOffset || offset;

  var y = 0;
  var lastDown = 0;
  var lastUp = 0;
  
  function handleScrollDirection() {
    var s = window.scrollY;
  
    if (y < s) {
      if (lastUp + downOffset < s) {
        el.classList.remove('scroll-up');
        el.classList.add('scroll-down');
        lastDown = s;
      }
    } else {
      if (lastDown - upOffset > s) {
        el.classList.remove('scroll-down');
        el.classList.add('scroll-up');
        lastUp = s;
      }
    }
  
    y = s;
  }

  window.addEventListener('scroll', throttle(handleScrollDirection, 16));
  window.addEventListener('resize', debounce(handleScrollDirection, 150));
}

export default scrollDirection;