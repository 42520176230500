class ImageSwap {
  constructor( selector, options ) {
    if ( selector instanceof HTMLElement ) {
      this.elements = [selector];
    } else if ( selector instanceof NodeList ) {
      this.elements = [...selector];
    } else if ( selector && document.querySelector(selector) ) {
      this.elements = [...document.querySelectorAll(selector)];
    }

    Object.assign(this, {
      className: '',
    }, options);

    this.init();
  }

  getAtts(img) {
    let atts = img.getAttributeNames();
    let attributes = {};
    atts.forEach(attr => attributes[attr] = img.getAttribute(attr));
    return attributes;
  }

  init() {
    if (this.elements) {
      this.elements.forEach( img => {
        if (img.ImageSwap && img.ImageSwap.init) return;
  
        let wrap = document.createElement('figure');
        wrap.classList.add('img-swap');
  
        if (this.className) {
          wrap.classList.add(this.className);
        }
  
        let swapImage = new Image();
        swapImage.classList.add('img-swap-hover');
        swapImage.src = img.dataset.hover;
        swapImage.width = this.getAtts(img).width;
        swapImage.height = this.getAtts(img).height;
        swapImage.alt = this.getAtts(img).alt || '';
        swapImage.loading = 'lazy';
  
        img.classList.add('img-swap-base');
        img.insertAdjacentElement('afterend', wrap);
        wrap.appendChild(img);
        wrap.appendChild(swapImage);
  
        img.ImageSwap = {
          init: true
        };
      });
    }
  }
}

export default ImageSwap;