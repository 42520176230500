/**
 * Get month in list by year
 * 
 * @param {int} yearnum 
 * @returns {array} ArrayMonth
 */
const getMonthList = yearnum => wp.ajax.post('get_blog_monthly_by_year', {
  year: yearnum,
});

let blogFilter = document.querySelectorAll('.blog-filter');
blogFilter.forEach(form => {
  let fieldYear = form.querySelector('[name=year]');
  let fieldMonth = form.querySelector('[name=monthnum]');
  let submitButton = form.querySelector('.submit-button');
  let updateMonthOption = (year, selectedMonth = null) => {
    let d = getMonthList(year);
    let loadingText = wp.i18n._x('Loading...', 'blog:filter', 'therock');
    fieldMonth.disabled = true;
    fieldMonth.innerHTML = `<option>${loadingText}</option>`;
    submitButton.disabled = true;

    if (!year) {
      fieldMonth.innerHTML = `<option>month</option>`;
      return;
    }

    d.done(function(res) {
      res.sort((a, b) => {
        return b.num - a.num;
      });

      var options = '<option disabled>month</option>';
      res.forEach(item => {
        options += `<option value="${item.num}" ${parseInt(selectedMonth) === parseInt(item.num) ? 'selected' : ''}>${item.title}</option>`;
      });

      fieldMonth.innerHTML = options;
      fieldMonth.disabled = false;
      submitButton.disabled = false;
    });
  }

  let year = parseInt(fieldYear[fieldYear.selectedIndex].innerText);
  let month = parseInt(therock.blog.currentMonth) || null;
  updateMonthOption(year, month);

  fieldYear.addEventListener('change', ev => {
    let t = ev.target;
    let y = parseInt(t[t.selectedIndex].innerText);
    updateMonthOption(y);
  });

  submitButton.addEventListener('click', ev => {
    ev.preventDefault();
    let y = fieldYear[fieldYear.selectedIndex].getAttribute('value');
    let m = fieldMonth[fieldMonth.selectedIndex].getAttribute('value');
    window.location = y + m + '/';
  })
});