import anime from 'animejs';

let showClass = 'fs-menu-show';

var fsmenuAnimation = anime({
  targets: '.panel-menu .menu-item',
  translateX: [-20, 0],
  opacity: [0, 1],
  autoplay: false,
  delay: anime.stagger(20),
});

var Methods = {
  show() {
    document.body.classList.add(showClass);
    fsmenuAnimation.play();
    document.addEventListener('keyup', escapeToCloseMenu);
  },
  hide() {
    document.body.classList.remove(showClass);
    document.removeEventListener('keyup', escapeToCloseMenu);
  },
  toggle() {
    if (this.isShow()) {
      this.hide();
    } else {
      this.show();
    }
  },
  isShow() {
    return document.body.classList.contains(showClass);
  }
}

/**
 * Bind event to ESC button to close fullscreen menu
 * @param {KeyboardEvent} event 
 */
function escapeToCloseMenu(event) {
  if ('Escape' === event.code) {
    Methods.hide();
  }
}

window.therock.fn['fullScreenMenuPanel'] = Methods;
