import {debounce} from 'lodash';
import scrollDirection from '../utils/scroll-direction';

//
// Bottom Navigation
// 
let el = document.querySelector('.bottom-nav');
if (el) {
  scrollDirection( el );

  let start = 0;

  window.addEventListener('resize', ev => {
    if (!start) {
      el.classList.add('resizing');
      start = 1;
    }
  });

  window.addEventListener('resize', debounce(() => {
    el.classList.remove('resizing');
    start = 0;
  }, 150));
}