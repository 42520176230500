import {throttle, debounce} from 'lodash';

//
// Header State
// 
var body = document.body;
var siteHeader = document.querySelector('.site-header');
var offset = 0;

function hasAdminbar() {
  return body.classList.contains('admin-bar');
}

function getOffset() {
  let w = window.innerWidth;
  if (hasAdminbar()) {
    return w <= 600 ? 46 : 0;
  }
  return 0;
}

function headerStage() {
  siteHeader.classList.toggle('stage-2', scrollY > 300);
}

function headerFixed() {
  siteHeader.classList.toggle('fixed', scrollY >= offset);
}

function updatePosition() {
  offset = getOffset();
  headerStage();
  headerFixed();
}

if ('complete' !== document.readyState) {
  document.addEventListener('DOMContentLoaded', updatePosition);
} else {
  updatePosition();
}

window.addEventListener('scroll', throttle(headerStage, 16));
window.addEventListener('scroll', throttle(headerFixed, 16));
window.addEventListener('resize', debounce(updatePosition, 150));