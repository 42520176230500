import $ from 'jquery';

/**
 * WebsiteData
 * @param {ObjectConstructor} params 
 * @param {ObjectConstructor} options 
 */
export function WebsiteData( params, options ) {
  let defaultParams = {
    formKey: '',
    name: '',
    phone: '',
    email: '',
    countryCode: '',
    countryName: '',
    message: '',
  }

  let callbacks = {
    beforeSend: function(){},
    done: function(){},
    fail: function(){},
    always: function(){},
  }

  let data = $.extend({}, defaultParams, params);
  let cb = $.extend(callbacks, options);

  let submit = $.post({
    url: 'https://hotelservices.travelanium.net/crs-customer-form-rs/restresources/CRSCustomerFormDataService',
    contentType: 'application/json',
    data: JSON.stringify(data),
    beforeSend(jqXHR) {
      cb.beforeSend(jqXHR);
    }
  });

  submit.done((res, status, xhr) => {
    cb.done(res, status, xhr);
  });

  submit.fail((xhr, status, res) => {
    cb.fail(xhr, status, res);
  });

  submit.always(() => {
    cb.always();
  });
}

export var Member = {
  isMember() {
    return Cookies.get('_tl_member') ? true : false;
  },

  setMember() {
    return Cookies.set('_tl_member', '1', {
      expires: 365,
    })
  },

  delMember() {
    return Cookies.remove('_tl_member');
  },

  transformLink() {
    var links = document.body.querySelectorAll('a[href^="https://reservation"]:not([href*="accesscode="])');
    var inst = new Booking({
      accesscode: therock.member.code
    });
    Array.from(links).forEach(link => {
      link.setAttribute('href', inst.getURL());
      link.setAttribute('target', '_blank');
    });
  }
}

export function Booking(params) {
  var API = therock.reservation;
  var LANG = document.documentElement.lang || 'en';
  var defaults = {
    onlineId: API.onlineId,
    propertyId: API.propertyId,
    checkinauto: API.defaultReservationStart,
    numofnight: API.defaultReservationEnd,
    numofroom: API.defaultNumofroom,
    numofadult: API.defaultNumofadult,
    numofchild: API.defaultNumofchild,
    currency: API.currency,
    lang: LANG
  }
  var options = {};

  Object.assign(options, defaults, params);

  return {
    _baseURL() {
      return `https://reservation.${API.reservationDomain}/propertyibe2/`;
    },
    _params() {
      let count = 0;
      let query = '';
      for (const key in options) {
        var val = options[key];
        var conj = count > 0 ? '&' : '?';
        query += `${conj}${key}=${val}`;
        count++;
      }
      return query;
    },
    getURL() {
      return this._baseURL() + this._params();
    }
  };
}

export default {
  WebsiteData,
  Booking,
  Member,
}